import { createActionCreator } from '@reduxify/utils';

import {
  SET_CODE,
  SET_ERROR_MSG,
  SET_FIRST_VISIT_DATE,
  SET_GEOLOCATION,
  SET_MANDITORY_UPGRADES,
  SET_QUIZ_ANSWERS,
  SET_SELECTED_PLAN,
  SET_SELECTED_PLANS,
  SET_SELECTED_PLANS_OPTIONS,
  SET_SHIPPING_ADDRESS,
  SET_SUB_CONFIG,
  SET_TEST_SHIPPING,
  SET_UPSELL_PRODUCTS,
  SET_USER,
  SET_USER_IP,
} from './constants';
import { IUserState } from './types';

export const setGeolocation =
  createActionCreator<IUserState['geolocation']>(SET_GEOLOCATION);
export const setUserIp =
  createActionCreator<IUserState['user_ip']>(SET_USER_IP);
export const setQuizAnswers =
  createActionCreator<IUserState['quiz_answers']>(SET_QUIZ_ANSWERS);
export const setUser = createActionCreator<IUserState['user']>(SET_USER);
export const setManditoryUpagrade = createActionCreator<
  IUserState['selected_manditory_upgrades']
>(SET_MANDITORY_UPGRADES);
export const setCode = createActionCreator<IUserState['code']>(SET_CODE);
export const setFirstVisitDate =
  createActionCreator<IUserState['code']>(SET_FIRST_VISIT_DATE);
export const setErrorMsg =
  createActionCreator<IUserState['error_msg']>(SET_ERROR_MSG);
export const setSelectedPlan =
  createActionCreator<IUserState['selected_plan']>(SET_SELECTED_PLAN);
export const setSelectedPlans =
  createActionCreator<IUserState['selected_plans']>(SET_SELECTED_PLANS);
export const setUpsellProducts =
  createActionCreator<IUserState['upsell_products']>(SET_UPSELL_PRODUCTS);
export const setSubscriptionConfig =
  createActionCreator<IUserState['subscription_config']>(SET_SUB_CONFIG);
export const setSelectedPlansOptions = createActionCreator<
  IUserState['selected_plans_options']
>(SET_SELECTED_PLANS_OPTIONS);
export const setShippingAddress =
  createActionCreator<IUserState['shipping_address']>(SET_SHIPPING_ADDRESS);
export const setTestShipping =
  createActionCreator<IUserState['test_shipping_state']>(SET_TEST_SHIPPING);
