import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import type { Color } from 'utils/theme';
import { CircularProgress } from '@material-ui/core';

interface PrimaryButtonProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'button'>> {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  cornerVariant?: 'default' | 'round';
  minHeight?: number;
  color?: Color;
  colorActive?: Color;
  fontSize?: number;
  fontFamily?: string;
  fontColor?: string;
  loading?: boolean;
}

const Container = styled(ButtonContainer)<{
  borderRadius: number;
  active?: boolean;
  disabled?: boolean;
  minHeight?: number;
  color: Color;
  colorActive: Color;
}>`
  display: flex;
  border: none;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 3)}rem;
  padding: ${({ minHeight }) => (minHeight && minHeight < 3 ? 0 : 0.81)}rem;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius}rem;
  background-color: ${({ theme, active, color, colorActive }) =>
    theme.colors[active ? colorActive : color]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 0.15s;
`;

const ButtonText = styled(Text).attrs({
  type: 'tabActive',
})<{ fontSize?: number; fontFamily?: string; fontColor?: string }>`
  font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.typography.tabActive.fontSize}rem;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Open Sans')};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#FFF')};
`;

const CircularProgressStyled = styled(CircularProgress)`
  height: 1.25rem;
  width: 1.25rem;
`;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  active,
  disabled,
  cornerVariant = 'default',
  minHeight,
  color = 'green',
  colorActive = 'greenDark',
  fontSize,
  fontFamily,
  fontColor,
  loading,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (cornerVariant === 'round' ? 10 : 0.5),
    [cornerVariant],
  );

  return (
    <Container
      onClick={onClick}
      as="button"
      borderRadius={borderRadius}
      active={active}
      disabled={disabled}
      minHeight={minHeight}
      color={color}
      colorActive={colorActive}
      {...props}
    >
      {loading ? (
        <CircularProgressStyled style={{ color: '#fff' }} size={30} />
      ) : (
        <ButtonText
          fontColor={fontColor}
          fontSize={fontSize}
          fontFamily={fontFamily}
        >
          {children}
        </ButtonText>
      )}
    </Container>
  );
};

export default React.memo(PrimaryButton);
