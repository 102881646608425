import React, { FC } from 'react';
import styled from 'styled-components';
import Burger from 'assets/icons/burger.svg';
import BurgerClose from 'assets/icons/burger-close.svg';
import { tablet } from 'styles/breakpoints';

interface BurgerMenuButtonProps {
  isOpen: boolean;
  toggleBurger: () => void;
}

const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({
  isOpen,
  toggleBurger,
}) => (
  <BurgerContainer onClick={toggleBurger}>
    {isOpen ? <BurgerClose /> : <Burger />}
  </BurgerContainer>
);

export default BurgerMenuButton;

const BurgerContainer = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
  svg {
    transition: all 0.5s ease;
  }
`;
