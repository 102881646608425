import React, { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from '../../../../assets/images/lastingChange/soc-prof-mob.png';
import socProof_des from '../../../../assets/images/lastingChange/soc-prof-des.png';
import bfSocProof from '../../../../assets/images/lastingChange/bf-soc-prof-mob.png';
import bfSocProof_des from '../../../../assets/images/lastingChange/bf-soc-prof-des.png';
import xmasSocProof from '../../../../assets/images/lastingChange/xmas-soc-prof-mob.png';
import xmasSocProof_des from '../../../../assets/images/lastingChange/xmas-soc-prof-des.png';
import nySocProof from '../../../../assets/images/lastingChange/ny-soc-prof-mob.png';
import nySocProof_des from '../../../../assets/images/lastingChange/ny-soc-prof-des.png';
import valentineSocProof from '../../../../assets/images/lastingChange/valentine-soc-prof-mob.png';
import valentineSocProof_des from '../../../../assets/images/lastingChange/valentine-soc-prof-des.png';
import PersonalisedIcons from './PersonalisedIcons';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import { useScrollToTop } from 'utils/hooks';
import PRFooter from 'components/PRFooter';
import Header from 'components/reviews/Header';
import { useLocation } from '@reach/router';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isMobile } = useQuery();
  const location = useLocation();
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;
  const pickSocialProfImage = (variant?: string) => {
    if (variant === 'blackFriday') {
      return {
        mob: bfSocProof,
        des: bfSocProof_des,
      };
    }
    if (variant === 'xmas') {
      return {
        mob: xmasSocProof,
        des: xmasSocProof_des,
      };
    }
    if (variant === 'ny') {
      return {
        mob: nySocProof,
        des: nySocProof_des,
      };
    }
    if (variant === 'valentine') {
      return {
        mob: valentineSocProof,
        des: valentineSocProof_des,
      };
    }
    return {
      mob: socProof,
      des: socProof_des,
    };
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) return null;
    if (isMobile && question?.bfLabel) return null;
    return options?.map((option, index) => {
      const hasColor = false;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text color="dark100" type="body500">
              {option?.label}
            </Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Container>
        {!isAddsFlow && <Header isMobileApp={false} />}

        <Wrapper isFullHeight={isAddsFlow}>
          {isAddsFlow && (
            <HeaderContainer>
              <HeaderImg src="lastingChange/LastingChange_mob.png" alt="" />
            </HeaderContainer>
          )}

          <W1>
            <SocialProof
              quizParam={quiz}
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
              isFullHeight={isAddsFlow}
            />
          </W1>

          <WrappedItem>
            <W3>
              <TitleContainer>
                <Title
                  type="h13Rem"
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>
              {question?.description ? (
                <DescriptionContainer>
                  <Label
                    type={isMobile ? 'body' : 'h3400'}
                    color="dark80"
                    dangerouslySetInnerHTML={{
                      __html: question?.description || '',
                    }}
                    descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                  />
                </DescriptionContainer>
              ) : null}
              {question?.bulletpoints ? (
                <BulletPointContainer>
                  {question?.bulletpoints.map((bp: string, index: number) => (
                    <BulletPoint key={index}>{bp}</BulletPoint>
                  ))}
                </BulletPointContainer>
              ) : null}
              <ButtonsTitleContainer>
                <Label
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                  type={isMobile ? 'bodyS500' : 'body500'}
                  fontSizing={isMobile ? 0.75 : 1}
                />
              </ButtonsTitleContainer>
              {renderGenderOptions()}
              {question?.bfLabel ? (
                <BlackFridayLandingLabel {...question.bfLabel} />
              ) : null}
              {question.personalisedIcons ? (
                <PersonalisedIcons {...question.personalisedIcons} />
              ) : null}
            </W3>
          </WrappedItem>
        </Wrapper>
        {/* <AsSeenOnWrap>
          <AsSeenOn
            src={
              isMobile
                ? 'lastingChange/AsSeen_mob.png'
                : 'lastingChange/AsSeen.png'
            }
            alt=""
          />
        </AsSeenOnWrap> */}
        {!isAddsFlow ? (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  @media ${tablet} {
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: 72px;
  @media ${tablet} {
    padding: 0 1rem;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  max-width: 90rem;
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light0};
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    padding: 0;
    flex-direction: column;
    align-items: unset;
    gap: 1.5rem;
  }
`;

const HeaderImg = styled(DynamicImage)`
  height: 24px;
  width: 182px;
  @media ${mobile} {
    height: 20px;
  }
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  height: 72px;
  left: 3rem;
  margin: 0 auto;
  width: 100%;

  @media ${tablet} {
    position: static;
    top: auto;
    height: 68px;
    align-items: center;
    justify-content: center;
    padding-left: unset;
    width: unset;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;

  }

  to {
    background-position: 0 10000%;

  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;

  }

  to {
    background-position: 10000% 0;

  }
`;

const SocialProof = styled.div<{
  quizParam: string;
  des: string;
  mob: string;
  isFullHeight?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});

    height: 308px;
  }
`;

const AsSeenOnWrap = styled.div`
  background: #fbfbff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  @media ${mobile} {
    padding: unset;
  }
`;

const AsSeenOn = styled(DynamicImage)`
  max-width: 816px;
  max-height: 164px;
  @media ${mobile} {
    max-height: unset;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  @media ${tablet} {
    padding-bottom: 32px;
    justify-content: center;
  }
`;

const Title = styled(Text)`
  font-size: 3.5rem;
  font-weight: 700;
  max-width: 547px;
  text-align: left;

  @media ${tablet} {
    text-align: center;
    font-size: 1.375rem;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  font-weight: 400;

  * {
    font-size: inherit;
    font-family: 'Open Sans';
  }
  font-family: 'Open Sans';
  @media ${tablet} {
    font-size: ${({ fontSizing }) => (fontSizing ? `${fontSizing}rem` : '1rem')}
    width: ${({ descriptionIsMaxWidth }) =>
      descriptionIsMaxWidth ? '100%' : '21.438rem'};
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;

  @media ${tablet} {
    text-align: center;
    text-align-last: center;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  display: flex;
  width: 100%;

  @media ${tablet} {
    justify-content: center;
    text-align: center;
    padding-bottom: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  max-width: 15rem;
  margin-right: 1rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${tablet} {
    font-family: 'Open Sans';
    height: 3rem;
    max-width: 30rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  color: black;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 0.5rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;

const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2.25rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const BulletPoint = styled(Text)`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #000000;
  @media ${tablet} {
    text-align: center;
  }
`;
