import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
}
// border: ${({ active }) => (active ? 0.09 : 0.06)}rem solid
// ${({ theme, active }) => active ? 'light100' : '#21155E'};
const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
}>`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  border: ${({ active }) =>
    active ? '2px solid rgba(255, 135, 90, 0.50)' : 'unset'};

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 62.5rem;
  border: 1px solid #804da1;

  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ active }) => (active ? '#804da1' : '#FFF')};

  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};

  @media ${mobile} {
    width: unset;
    min-height: 3rem;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 80px;
  max-height: 64px;
`;

const ButtonText = styled(Text).attrs({
  type: 'button',
})<{
  active?: boolean;
}>`
  font-weight: 400;
  color: ${({ active }) => (active ? '#FFF' : '#804da1')};
  @media ${mobile} {
    font-size: 0.875rem;
  }
`;

const NewQuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  ...props
}) => (
  <Container
    onClick={onClick}
    active={active}
    maxSelected={maxSelected}
    as="button"
    {...props}
  >
    {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
    <ButtonText active={active} color="light0">
      {' '}
      {children}
    </ButtonText>
  </Container>
);

export default React.memo(NewQuizAnswerButton);
