import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';

interface ProgressCountProps {
  total: number;
  count: number;
}

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <Text type="body" color="dark80">
    {count}/{total}
  </Text>
);

export default ProgressCount;
