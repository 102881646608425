import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

interface DisclaimerProps {
  paragraphs: string[];
}

const Container = styled.div`
  padding-top: 5rem;

  @media ${mobile} {
    text-align: center;
  }
`;

const Disclaimer: FC<DisclaimerProps> = ({ paragraphs, ...props }) => {
  const { isMobile } = useQuery();
  return (
    <Container {...props}>
      {paragraphs.map((paragraph, index) => (
        <Text key={index} type={isMobile ? 'bodyS' : 'bodyM'} color="dark60">
          {paragraph}
        </Text>
      ))}
    </Container>
  );
};

export default Disclaimer;
