import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  setCode,
  setErrorMsg,
  setFirstVisitDate,
  setGeolocation,
  setManditoryUpagrade,
  setQuizAnswers,
  setSelectedPlan,
  setSelectedPlans,
  setSelectedPlansOptions,
  setShippingAddress,
  setSubscriptionConfig,
  setTestShipping,
  setUpsellProducts,
  setUser,
  setUserIp,
} from './actions';
import { IUserState } from './types';
import { getUnixDate } from 'utils/functions';

const quiz_answers = createReducer<IUserState['quiz_answers']>(
  {},
  reduce(setQuizAnswers, merge),
);
const user = createReducer<IUserState['user']>(null, reduce(setUser, set));
const code = createReducer<IUserState['code']>(null, reduce(setCode, set));
const firstVisitDate = createReducer<IUserState['firstVisitDate']>(
  getUnixDate(),
  reduce(setFirstVisitDate, set),
);
const geolocation = createReducer<IUserState['geolocation']>(
  null,
  reduce(setGeolocation, set),
);

const user_ip = createReducer<IUserState['user_ip']>(
  null,
  reduce(setUserIp, set),
);
const selected_plan = createReducer<IUserState['selected_plan']>(
  null,
  reduce(setSelectedPlan, set),
);

const selected_manditory_upgrades = createReducer<
  IUserState['selected_manditory_upgrades']
>([], reduce(setManditoryUpagrade, set));

const selected_plans = createReducer<IUserState['selected_plans']>(
  null,
  reduce(setSelectedPlans, set),
);
const upsell_products = createReducer<IUserState['upsell_products']>(
  [],
  reduce(setUpsellProducts, set),
);
const error_msg = createReducer<IUserState['error_msg']>(
  null,
  reduce(setErrorMsg, set),
);

const shipping_address = createReducer<IUserState['shipping_address']>(
  null,
  reduce(setShippingAddress, set),
);

const test_shipping_state = createReducer<IUserState['test_shipping_state']>(
  {
    isShippingTest: false,
    addressError: false,
    loading: false,
  },
  reduce(setTestShipping, set),
);

const subscription_config = createReducer<IUserState['subscription_config']>(
  null,
  reduce(setSubscriptionConfig, set),
);

const selected_plans_options = createReducer<
  IUserState['selected_plans_options']
>(
  {
    ebookCheck: true,
    paperBook: false,
    subContainer: true,
    tAndC: true,
    selectedSub: null,
    subscriptions: null,
  },
  reduce(setSelectedPlansOptions, set),
);

export default combineReducers<IUserState>({
  code,
  firstVisitDate,
  geolocation,
  quiz_answers,
  user,
  error_msg,
  user_ip,
  selected_plan,
  upsell_products,
  subscription_config,
  selected_plans,
  selected_plans_options,
  selected_manditory_upgrades,
  shipping_address,
  test_shipping_state,
});
