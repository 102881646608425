import React from 'react';

import styled from 'styled-components';

import star from 'assets/icons/star.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  height: 100%;
`;
const StarIcon = styled(star)<{ $color?: string }>`
  height: 16px;
  width: 16px;
  path {
    fill: ${({ $color, theme }) => $color || theme.colors.yellow80};
  }
`;
type ReviewsStarsRowProps = {
  color?: string;
};

const ReviewsStarsRow = ({ color }: ReviewsStarsRowProps) => {
  const ReviewsArray = [1, 2, 3, 4, 5];
  return (
    <Container>
      {ReviewsArray.map(item => (
        <StarIcon key={item} $color={color} />
      ))}
    </Container>
  );
};
export default ReviewsStarsRow;
