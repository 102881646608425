import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile, useQuery } from 'styles/breakpoints';

import Text from './Text';

import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from './images/DynamicImage';
import ReviewsStarsRow from './reviews/ReviewsStarsRow';
import theme from 'utils/theme';

type reviewItem = {
  image?: string;
  name?: string;
  text?: string;
};
interface ReviewsProps {
  title: string;
  reviews: reviewItem[];
  starImgUrl?: string;
  starImgUrlAlt: string;
  onClick?: () => void;
  mobileTitleAlt?: string;
  bgColor?: string;
}

const Container = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${mobile} {
    padding-top: 0;
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
`;

const StarImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 16.826875rem;
  @media ${mobile} {
    width: 11rem;
  }
`;

const Title = styled(Text).attrs({
  type: 'h1',
})`
  font-size: 2rem;
  max-width: 560px;
  line-height: 2.375rem;
  text-align: center;
  margin: 0 auto;
  font-weight: 700;

  @media ${mobile} {
    font-size: 1.625rem;
    line-height: 1.625rem;
    font-weight: 700;
  }
`;

const SwiperStyled = styled(Swiper)`
  margin-top: 48px;
  margin: 3rem 1rem;
  .swiper-slide {
    margin-bottom: 2.5rem;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: ${({ theme }) => theme.colors.light60};

    &-active {
      background-color: ${({ theme }) => '#804DA1'};
      width: 8px;
    }
  }

  .swiper-wrapper {
    margin-bottom: 3.375rem;
  }

  @media ${mobile} {
    margin-top: 1.5rem;

    .swiper-wrapper {
      margin-bottom: 1.875rem;
    }
  }
`;

const WhatYouGet = styled(Text)`
  font-size: 0.675rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: -1.25rem;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  padding: 1.25rem;
  gap: 0.5rem;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Reviews: FC<ReviewsProps> = ({
  title,
  reviews,
  starImgUrl,
  starImgUrlAlt,
  onClick,
  mobileTitleAlt,
  bgColor = '#ffffff',
}) => {
  const { isTablet } = useQuery();
  return (
    <Container bgColor={bgColor}>
      <InnerWrapper>
        {!!starImgUrl && (
          <StarImageContainer>
            <StyledDynamicImage src={starImgUrl} alt={starImgUrlAlt} />
          </StarImageContainer>
        )}
        {mobileTitleAlt ? (
          <WhatYouGet>{mobileTitleAlt}</WhatYouGet>
        ) : (
          <Text textAlign="center" type={isTablet ? 'h3700' : 'h1700'}>
            {title}
          </Text>
        )}

        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove
          effect="slide"
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <CardContainer>
                {review.image && (
                  <DynamicImage src={review.image} alt="Slide" />
                )}

                <RowWrapper>
                  <Text type="body600" color="dark80">
                    {review.name}
                  </Text>
                  <ReviewsStarsRow color={theme.colors.orange} />
                </RowWrapper>

                <Text type="bodyM" color="dark80">
                  {review.text}
                </Text>
              </CardContainer>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </InnerWrapper>
    </Container>
  );
};

export default Reviews;
