import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile, smMobile } from 'styles/breakpoints';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
}
// border: ${({ active }) => (active ? 0.09 : 0.06)}rem solid
// ${({ theme, active }) => active ? 'light100' : '#21155E'};
const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
}>`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  border: ${({ active }) =>
    active ? '2px solid rgba(255, 135, 90, 0.50)' : 'unset'};

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 62.5rem;

  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ active }) =>
    active
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #FF875A'
      : '#ff875a'};

  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};

  @media ${mobile} {
    width: unset;
    min-height: 2.81rem;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 80px;
  max-height: 64px;
`;

const ButtonText = styled(Text).attrs({
  type: 'button',
})`
  font-weight: 400;
  @media ${mobile} {
    padding: 0 0.5rem;
    word-break: break-word;
    font-size: 0.875rem;
  }
  @media ${smMobile} {
    font-size: 0.75rem;
  }
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  ...props
}) => (
  <Container
    onClick={onClick}
    active={active}
    maxSelected={maxSelected}
    as="button"
    {...props}
  >
    {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
    <ButtonText color="light0"> {children}</ButtonText>
  </Container>
);

export default React.memo(QuizAnswerButton);
