import { createRndApi } from '@frontend/api-wrapper';
import axios from 'axios';

import { config } from 'config';
import { getLocalisedIsoCountry } from 'utils/localization';

export const Api = createRndApi({
  baseUrl: config.BASE_URL,
  api_token: config.API_TOKEN,
});

export const ApiBase = axios.create({
  baseURL: config.BASE_URL,
  params: {
    api_token: config.API_TOKEN,
    p_locale: getLocalisedIsoCountry().toLowerCase(),
  },
});

export const TempApiBase = axios.create({
  baseURL: config.BASE_URL,
  params: {
    api_token: config.TEMP_LC_KEY,
    p_locale: getLocalisedIsoCountry().toLowerCase(),
  },
});

export const TempApi = createRndApi({
  baseUrl: config.BASE_URL,
  api_token: config.TEMP_LC_KEY,
});
