/**
 * Interfaces below are written in accordance with parameters which Google Analytics expects to receive
 */
import { ProductTypes } from '@frontend/api-wrapper';

export enum GAEvent {
  QuizStarted = 'start_quiz',
  QuizQuestionAnswered = 'quiz_question_answered',
  ArticleLinkClicked = 'article_link',
  UserCodeGenerated = 'generate_lead',
  ReachedCheckout = 'begin_checkout',
  InitiateCheckout = 'initiate_checkout',
  InitiateCheckoutTikTok = 'initiate_checkout_tt',
  PaymentInfoAdded = 'add_payment_info',
  PurchaseEvent = 'purchase',
  PurchaseMainEvent = 'purchase_main',
  UpsellPurchaseEvent = 'purchase_upsell',
  PurchaseUpsellLifetime = 'purchase_upsell_lifetime',
  DownsellPurchaseEvent = 'purchase_downsell',
  ShippingInfoProvided = 'add_shipping_info',
  ItemSelected = 'select_item',
  AddToCart = 'add_to_cart',
  trackCTAButton = 'Click',
  SuccessfulRegistration = 'SuccessfulRegistration',
  LogEvent = 'LogEvent',
  PaymentInitialized = 'payment_initialized',
  VirtualPageView = 'virtualPageview',
}

export interface GAItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency: string;
  discount?: number;
  purchase_type?: string;
  index?: number;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price: number;
  original_price: number;
  quantity?: number;
  key: string;
  type: ProductTypes;
}

export interface GAQuestionAnswer {
  event: string;
  category: string;
  label: string;
}

export interface GAPageView {
  client_code?: string | null;
  country?: string | null;
  city?: string | null;
  state?: string | null;
  gender?: string | null;
  email?: string | null;
  zipCode?: string | null;
  phoneNumber?: string | null;
}

interface GAPaymentBase {
  user_id?: string;
  email?: string;
  value?: number;
  currency?: string;
  items?: GAItem[];
  coupon?: string;
}

export interface GAItemSelected {
  user_id?: string;
  email?: string;
  items?: GAItem[];
  currency?: string;
  value?: number;
}

export interface GAPaymentInfo extends GAPaymentBase {
  payment_type?: string;
}

export interface GAShippingInfo extends GAPaymentBase {
  shipping_tier?: string;
}

export interface GAPurchaseInfo extends GAPaymentInfo {
  email?: string;
  transaction_id?: string;
  affiliation?: string;
  tax?: string;
  shipping?: string;
  purchase_type?: string;
}

export interface GoogleAnalyticsMainItem {
  sku: string;
  name: string;
  category: string;
  price: string;
  quantity: number;
  currency: string;
}

export interface GAMainPurchaseInfo {
  user_id: string;
  email: string;
  currencyCode: string;
  transactionId: number;
  transactionTotal: string;
  transactionProducts: GoogleAnalyticsMainItem[];
}

export interface GACartItem {
  content_category: string;
  content_id: string;
  content_name: string;
  content_type: string;
  currency: string;
  price: number;
  quantity: number;
}
