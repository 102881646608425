import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import salad from 'assets/icons/quiz/salad.svg';
import lotus from 'assets/icons/quiz/lotus.svg';
import sleep from 'assets/icons/quiz/sleep.svg';
import productivity from 'assets/icons/quiz/productivity.svg';
import mental from 'assets/icons/quiz/mental.svg';
import morning from 'assets/icons/quiz/morning.svg';
import { mobile, smLaptop } from 'styles/breakpoints';
import { SvgIconComponent } from '@material-ui/icons';

interface PersonalisedIconsProps {
  title: string;
  items: string[];
}

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  margin: 3rem 0;
  @media ${mobile} {
    margin: 0 0 2rem 0;
  }
`;

const Title = styled.p`
  color: #564789;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.018rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media ${smLaptop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: ${appear} 0.45s ease-in;
  animation-fill-mode: forwards;
`;

const Label = styled.p`
  color: #564789;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.018rem;
  text-align: center;
  margin-top: 0.45rem;
`;

const Icons: SvgIconComponent[] = [
  salad,
  lotus,
  sleep,
  productivity,
  mental,
  morning,
];

const PersonalisedIcons: FC<PersonalisedIconsProps> = ({ title, items }) => {
  const renderIcons = (Icon: SvgIconComponent, index: number) => {
    const delay = 0.2 * index;
    return (
      <ItemContainer key={index} style={{ animationDelay: `${delay}s` }}>
        <Icon />
        <Label>{items[index]}</Label>
      </ItemContainer>
    );
  };
  return (
    <Container>
      <Title>{title}</Title>
      <ContentContainer>{Icons.map(renderIcons)}</ContentContainer>
    </Container>
  );
};

export default PersonalisedIcons;
